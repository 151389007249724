
import { Fragment } from 'vue-frag'
import requestOfferConstants from '~/store/requestOffer/-constants'

export default {
  name: 'MoleculeAvailabilitySpaceFilterResultCombinedSpace',
  components: {
    Fragment
  },
  props: {
    displayData: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    },
    relatedSpace: {
      type: Object,
      required: true,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    listSpaceDetails() {
      return this.projectFeatures?.listSpaceDetails || ['sqm', 'people']
    },
    isAvailable() {
      return this.displayData.available
    },
    includeLeasedSpacesInResultsList() {
      return this.$store.state.availability.includeLeasedSpaces
    },
    shouldDisableAction() {
      return !this.isAvailable && !this.includeLeasedSpacesInResultsList
    },
    selectedSpace() {
      return this.$store.state.building.space.spaceData
    },
    requestedSpaces() {
      return this.$store.state.requestOffer.spaces
    },
    spaceIsRequested() {
      return this.requestedSpaces.find((space) => space.id === this.displayData.id)
    },
    projectFeatures() {
      return this.$store.getters?.featuresForProject(this.activeProject)
    },
    disableList() {
      return this.projectFeatures?.disableMyList
    },
    isEmbedPath() {
      return this.$route.path.includes('/embed')
    },
    isEmbedV2Path() {
      return this.$route.path.includes('/embed/v2')
    },
    buildings() {
      return this.$store.state.project.project.buildings
    },
    floors() {
      return this.$store.state.project.project.floors
    },
    floor() {
      return this.floors.find((f) => f.id === this.displayData.floor_id)
    },
    activeProject() {
      return this.$store.state.project.activeProject
    }
  },
  watch: {},
  mounted() {
  },
  methods: {
    addBothSpacesToCart(e) {
      e.preventDefault();
      e.stopPropagation();
      const space = {...this.relatedSpace}
      space.building = this.buildings.find((b) => b.id === this.relatedSpace.building_id)
      space.floor = this.floors.find((f) => f.id === this.relatedSpace.floor_id)

      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        space
      )

      const combinedSpace = {...this.displayData}
      combinedSpace.building = this.buildings.find((b) => b.id === this.displayData.building_id)
      combinedSpace.floor = this.floors.find((f) => f.id === this.displayData.floor_id)

      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        combinedSpace
      )
    },
    removeBothSpacesFromCart(e) {
      e.preventDefault();
      e.stopPropagation();
      const space = {...this.relatedSpace}
      space.building = this.buildings.find((b) => b.id === this.relatedSpace.building_id)
      space.floor = this.floors.find((f) => f.id === this.relatedSpace.floor_id)
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )

      const combinedSpace = {...this.displayData}
      combinedSpace.building = this.buildings.find((b) => b.id === this.displayData.building_id)
      combinedSpace.floor = this.floors.find((f) => f.id === this.displayData.floor_id)

      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        combinedSpace
      )
    },
    exploreSpace(e) {
      e.preventDefault();
      e.stopPropagation();
      const space = {
        ...this.displayData
      }
      space.building = this.buildings.find((b) => b.id === this.displayData.building_id);
      space.floor = this.floors.find((f) => f.id === this.displayData.floor_id);

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'explore_space_trigger',
          payload: {
            zone: 'building',
            spaceCode: space.space_code,
            buildingCode: space.building.code,
            floorCode: space.floor.code,
          }
        })
      }
      if (!this.isEmbedPath) {
        this.$router.push({
          path: `/project/${this.activeProject}/space/${space.id}#building`
        })
      } else {
        this.$router.push({
          path: `/embed/${this.isEmbedV2Path ? 'v2/' : ''}${this.activeProject}/space/${space.id}`
        })
      }
    }
  }
}
